export const stripeCurrencies = [
  { currency: 'USD', currencySymbol: '$', multiplier: 100, type: 'STRIPE' },
  { currency: 'AED', currencySymbol: 'د.إ', multiplier: 100, type: 'STRIPE' },
  { currency: 'AFN', currencySymbol: '؋', multiplier: 100, type: 'STRIPE' },
  { currency: 'ALL', currencySymbol: 'L', multiplier: 100, type: 'STRIPE' },
  { currency: 'AMD', currencySymbol: '֏', multiplier: 100, type: 'STRIPE' },
  { currency: 'ANG', currencySymbol: 'ƒ', multiplier: 100, type: 'STRIPE' },
  { currency: 'AOA', currencySymbol: 'Kz', multiplier: 100, type: 'STRIPE' },
  { currency: 'ARS', currencySymbol: '$', multiplier: 100, type: 'STRIPE' },
  { currency: 'AUD', currencySymbol: '$', multiplier: 100, type: 'STRIPE' },
  { currency: 'AWG', currencySymbol: 'ƒ', multiplier: 100, type: 'STRIPE' },
  { currency: 'AZN', currencySymbol: '₼', multiplier: 100, type: 'STRIPE' },
  { currency: 'BAM', currencySymbol: 'BAM', multiplier: 100, type: 'STRIPE' },
  { currency: 'BBD', currencySymbol: '$', multiplier: 100, type: 'STRIPE' },
  { currency: 'BDT', currencySymbol: '৳', multiplier: 100, type: 'STRIPE' },
  { currency: 'BGN', currencySymbol: 'лв', multiplier: 100, type: 'STRIPE' },
  { currency: 'BIF', currencySymbol: 'Fr', multiplier: 1, type: 'STRIPE' },
  { currency: 'BMD', currencySymbol: '$', multiplier: 100, type: 'STRIPE' },
  { currency: 'BND', currencySymbol: '$', multiplier: 100, type: 'STRIPE' },
  { currency: 'BOB', currencySymbol: 'Bs.', multiplier: 100, type: 'STRIPE' },
  { currency: 'BRL', currencySymbol: 'R$', multiplier: 100, type: 'STRIPE' },
  { currency: 'BSD', currencySymbol: '$', multiplier: 100, type: 'STRIPE' },
  { currency: 'BWP', currencySymbol: 'P', multiplier: 100, type: 'STRIPE' },
  { currency: 'BZD', currencySymbol: '$', multiplier: 100, type: 'STRIPE' },
  { currency: 'CAD', currencySymbol: '$', multiplier: 100, type: 'STRIPE' },
  { currency: 'CDF', currencySymbol: 'Fr', multiplier: 100, type: 'STRIPE' },
  { currency: 'CHF', currencySymbol: 'Fr', multiplier: 100, type: 'STRIPE' },
  { currency: 'CLP', currencySymbol: '$', multiplier: 1, type: 'STRIPE' },
  { currency: 'CNY', currencySymbol: '¥', multiplier: 100, type: 'STRIPE' },
  { currency: 'COP', currencySymbol: '$', multiplier: 100, type: 'STRIPE' },
  { currency: 'CRC', currencySymbol: '₡', multiplier: 100, type: 'STRIPE' },
  { currency: 'CVE', currencySymbol: '$', multiplier: 100, type: 'STRIPE' },
  { currency: 'CZK', currencySymbol: 'Kč', multiplier: 100, type: 'STRIPE' },
  { currency: 'DJF', currencySymbol: 'Fr', multiplier: 1, type: 'STRIPE' },
  { currency: 'DKK', currencySymbol: 'kr', multiplier: 100, type: 'STRIPE' },
  { currency: 'DOP', currencySymbol: '$', multiplier: 100, type: 'STRIPE' },
  { currency: 'DZD', currencySymbol: 'د.ج', multiplier: 100, type: 'STRIPE' },
  { currency: 'EGP', currencySymbol: '£', multiplier: 100, type: 'STRIPE' },
  { currency: 'ETB', currencySymbol: 'Br', multiplier: 100, type: 'STRIPE' },
  { currency: 'EUR', currencySymbol: '€', multiplier: 100, type: 'STRIPE' },
  { currency: 'FJD', currencySymbol: '$', multiplier: 100, type: 'STRIPE' },
  { currency: 'FKP', currencySymbol: '£', multiplier: 100, type: 'STRIPE' },
  { currency: 'GBP', currencySymbol: '£', multiplier: 100, type: 'STRIPE' },
  { currency: 'GEL', currencySymbol: '₾', multiplier: 100, type: 'STRIPE' },
  { currency: 'GIP', currencySymbol: '£', multiplier: 100, type: 'STRIPE' },
  { currency: 'GMD', currencySymbol: 'D', multiplier: 100, type: 'STRIPE' },
  { currency: 'GNF', currencySymbol: 'Fr', multiplier: 1, type: 'STRIPE' },
  { currency: 'GTQ', currencySymbol: 'Q', multiplier: 100, type: 'STRIPE' },
  { currency: 'GYD', currencySymbol: '$', multiplier: 100, type: 'STRIPE' },
  { currency: 'HKD', currencySymbol: '$', multiplier: 100, type: 'STRIPE' },
  { currency: 'HNL', currencySymbol: 'L', multiplier: 100, type: 'STRIPE' },
  { currency: 'HRK', currencySymbol: 'kn', multiplier: 100, type: 'STRIPE' },
  { currency: 'HTG', currencySymbol: 'G', multiplier: 100, type: 'STRIPE' },
  { currency: 'HUF', currencySymbol: 'Ft', multiplier: 100, type: 'STRIPE' },
  { currency: 'IDR', currencySymbol: 'Rp', multiplier: 100, type: 'STRIPE' },
  { currency: 'ILS', currencySymbol: 'ع.د', multiplier: 100, type: 'STRIPE' },
  { currency: 'INR', currencySymbol: '₹', multiplier: 100, type: 'STRIPE' },
  { currency: 'ISK', currencySymbol: 'kr', multiplier: 100, type: 'STRIPE' },
  { currency: 'JMD', currencySymbol: '$', multiplier: 100, type: 'STRIPE' },
  { currency: 'JPY', currencySymbol: '¥', multiplier: 1, type: 'STRIPE' },
  { currency: 'KES', currencySymbol: 'Sh', multiplier: 100, type: 'STRIPE' },
  { currency: 'KGS', currencySymbol: 'с', multiplier: 100, type: 'STRIPE' },
  { currency: 'KHR', currencySymbol: '៛', multiplier: 100, type: 'STRIPE' },
  { currency: 'KMF', currencySymbol: 'Fr', multiplier: 1, type: 'STRIPE' },
  { currency: 'KRW', currencySymbol: '₩', multiplier: 1, type: 'STRIPE' },
  { currency: 'KYD', currencySymbol: '$', multiplier: 100, type: 'STRIPE' },
  { currency: 'KZT', currencySymbol: '₸', multiplier: 100, type: 'STRIPE' },
  { currency: 'LAK', currencySymbol: '₭', multiplier: 100, type: 'STRIPE' },
  { currency: 'LBP', currencySymbol: 'ل.ل', multiplier: 100, type: 'STRIPE' },
  { currency: 'LKR', currencySymbol: 'Rs', multiplier: 100, type: 'STRIPE' },
  { currency: 'LRD', currencySymbol: '$', multiplier: 100, type: 'STRIPE' },
  { currency: 'LSL', currencySymbol: 'L', multiplier: 100, type: 'STRIPE' },
  { currency: 'MAD', currencySymbol: 'د.م.', multiplier: 100, type: 'STRIPE' },
  { currency: 'MDL', currencySymbol: 'L', multiplier: 100, type: 'STRIPE' },
  { currency: 'MGA', currencySymbol: 'Ar', multiplier: 1, type: 'STRIPE' },
  { currency: 'MKD', currencySymbol: 'ден', multiplier: 100, type: 'STRIPE' },
  { currency: 'MMK', currencySymbol: 'Ks', multiplier: 100, type: 'STRIPE' },
  { currency: 'MNT', currencySymbol: '₮', multiplier: 100, type: 'STRIPE' },
  { currency: 'MOP', currencySymbol: 'P', multiplier: 100, type: 'STRIPE' },
  { currency: 'MRO', currencySymbol: 'UM', multiplier: 100, type: 'STRIPE' },
  { currency: 'MUR', currencySymbol: '₨', multiplier: 100, type: 'STRIPE' },
  { currency: 'MVR', currencySymbol: '.ރ', multiplier: 100, type: 'STRIPE' },
  { currency: 'MWK', currencySymbol: 'MK', multiplier: 100, type: 'STRIPE' },
  { currency: 'MXN', currencySymbol: '$', multiplier: 100, type: 'STRIPE' },
  { currency: 'MYR', currencySymbol: 'RM', multiplier: 100, type: 'STRIPE' },
  { currency: 'MZN', currencySymbol: 'MT', multiplier: 100, type: 'STRIPE' },
  { currency: 'NAD', currencySymbol: '$', multiplier: 100, type: 'STRIPE' },
  { currency: 'NGN', currencySymbol: '₦', multiplier: 100, type: 'STRIPE' },
  { currency: 'NIO', currencySymbol: 'C$', multiplier: 100, type: 'STRIPE' },
  { currency: 'NOK', currencySymbol: 'kr', multiplier: 100, type: 'STRIPE' },
  { currency: 'NPR', currencySymbol: '₨', multiplier: 100, type: 'STRIPE' },
  { currency: 'NZD', currencySymbol: '$', multiplier: 100, type: 'STRIPE' },
  { currency: 'PAB', currencySymbol: 'B/.', multiplier: 100, type: 'STRIPE' },
  { currency: 'PEN', currencySymbol: 'S/.', multiplier: 100, type: 'STRIPE' },
  { currency: 'PGK', currencySymbol: 'K', multiplier: 100, type: 'STRIPE' },
  { currency: 'PHP', currencySymbol: '₱', multiplier: 100, type: 'STRIPE' },
  { currency: 'PKR', currencySymbol: '₨', multiplier: 100, type: 'STRIPE' },
  { currency: 'PLN', currencySymbol: 'zł', multiplier: 100, type: 'STRIPE' },
  { currency: 'PYG', currencySymbol: '₲', multiplier: 1, type: 'STRIPE' },
  { currency: 'QAR', currencySymbol: 'ر.ق', multiplier: 100, type: 'STRIPE' },
  { currency: 'RON', currencySymbol: 'lei', multiplier: 100, type: 'STRIPE' },
  { currency: 'RSD', currencySymbol: 'дин', multiplier: 100, type: 'STRIPE' },
  { currency: 'RUB', currencySymbol: '₽', multiplier: 100, type: 'STRIPE' },
  { currency: 'RWF', currencySymbol: 'Fr', multiplier: 1, type: 'STRIPE' },
  { currency: 'SAR', currencySymbol: 'ر.س', multiplier: 100, type: 'STRIPE' },
  { currency: 'SBD', currencySymbol: '$', multiplier: 100, type: 'STRIPE' },
  { currency: 'SCR', currencySymbol: '₨', multiplier: 100, type: 'STRIPE' },
  { currency: 'SEK', currencySymbol: 'kr', multiplier: 100, type: 'STRIPE' },
  { currency: 'SGD', currencySymbol: '$', multiplier: 100, type: 'STRIPE' },
  { currency: 'SHP', currencySymbol: '£', multiplier: 100, type: 'STRIPE' },
  { currency: 'SLL', currencySymbol: 'Le', multiplier: 100, type: 'STRIPE' },
  { currency: 'SOS', currencySymbol: 'Sh', multiplier: 100, type: 'STRIPE' },
  { currency: 'SRD', currencySymbol: '$', multiplier: 100, type: 'STRIPE' },
  { currency: 'STD', currencySymbol: 'Db', multiplier: 100, type: 'STRIPE' },
  { currency: 'SZL', currencySymbol: 'L', multiplier: 100, type: 'STRIPE' },
  { currency: 'THB', currencySymbol: '฿', multiplier: 100, type: 'STRIPE' },
  { currency: 'TJS', currencySymbol: 'ЅМ', multiplier: 100, type: 'STRIPE' },
  { currency: 'TOP', currencySymbol: 'T$', multiplier: 100, type: 'STRIPE' },
  { currency: 'TRY', currencySymbol: '₺', multiplier: 100, type: 'STRIPE' },
  { currency: 'TTD', currencySymbol: '$', multiplier: 100, type: 'STRIPE' },
  { currency: 'TWD', currencySymbol: '$', multiplier: 100, type: 'STRIPE' },
  { currency: 'TZS', currencySymbol: 'Sh', multiplier: 100, type: 'STRIPE' },
  { currency: 'UAH', currencySymbol: '₴', multiplier: 100, type: 'STRIPE' },
  { currency: 'UGX', currencySymbol: 'Sh', multiplier: 1, type: 'STRIPE' },
  { currency: 'UYU', currencySymbol: '$', multiplier: 100, type: 'STRIPE' },
  { currency: 'UZS', currencySymbol: 'сўм', multiplier: 100, type: 'STRIPE' },
  { currency: 'VND', currencySymbol: '₫', multiplier: 1, type: 'STRIPE' },
  { currency: 'VUV', currencySymbol: 'Vt', multiplier: 1, type: 'STRIPE' },
  { currency: 'WST', currencySymbol: 'T', multiplier: 100, type: 'STRIPE' },
  { currency: 'XAF', currencySymbol: 'Fr', multiplier: 1, type: 'STRIPE' },
  { currency: 'XCD', currencySymbol: '$', multiplier: 100, type: 'STRIPE' },
  { currency: 'XOF', currencySymbol: 'Fr', multiplier: 1, type: 'STRIPE' },
  { currency: 'XPF', currencySymbol: '₣', multiplier: 1, type: 'STRIPE' },
  { currency: 'YER', currencySymbol: '﷼', multiplier: 100, type: 'STRIPE' },
  { currency: 'ZAR', currencySymbol: 'Rs', multiplier: 100, type: 'STRIPE' },
  { currency: 'ZMW', currencySymbol: 'ZK', multiplier: 100, type: 'STRIPE' }
]

export const paypalCurrencies = [
  { currency: 'AUD', currencySymbol: '$', type: 'PAYPAL' },
  { currency: 'BRL', currencySymbol: 'R$', type: 'PAYPAL' },
  { currency: 'CAD', currencySymbol: '$', type: 'PAYPAL' },
  { currency: 'CZK', currencySymbol: 'Kč', type: 'PAYPAL' },
  { currency: 'DKK', currencySymbol: 'kr', type: 'PAYPAL' },
  { currency: 'EUR', currencySymbol: '€', type: 'PAYPAL' },
  { currency: 'HKD', currencySymbol: '$', type: 'PAYPAL' },
  { currency: 'HUF', currencySymbol: 'Ft', type: 'PAYPAL' },
  { currency: 'INR', currencySymbol: '₹', type: 'PAYPAL' },
  { currency: 'ILS', currencySymbol: 'ع.د', type: 'PAYPAL' },
  { currency: 'JPY', currencySymbol: '¥', type: 'PAYPAL' },
  { currency: 'MYR', currencySymbol: 'RM', type: 'PAYPAL' },
  { currency: 'MXN', currencySymbol: '$', type: 'PAYPAL' },
  { currency: 'TWD', currencySymbol: '$', type: 'PAYPAL' },
  { currency: 'NZD', currencySymbol: '$', type: 'PAYPAL' },
  { currency: 'NOK', currencySymbol: 'kr', type: 'PAYPAL' },
  { currency: 'PHP', currencySymbol: '₱', type: 'PAYPAL' },
  { currency: 'PLN', currencySymbol: 'zł', type: 'PAYPAL' },
  { currency: 'GBP', currencySymbol: '£', type: 'PAYPAL' },
  { currency: 'RUB', currencySymbol: '₽', type: 'PAYPAL' },
  { currency: 'SGD', currencySymbol: '$', type: 'PAYPAL' },
  { currency: 'SEK', currencySymbol: 'kr', type: 'PAYPAL' },
  { currency: 'CHF', currencySymbol: 'Fr', type: 'PAYPAL' },
  { currency: 'THB', currencySymbol: '฿', type: 'PAYPAL' },
  { currency: 'USD', currencySymbol: '$', type: 'PAYPAL' }
]
