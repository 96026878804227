// Import the functions you need from the SDKs you need
import * as firebase from 'firebase/app'
import { getMessaging, isSupported } from 'firebase/messaging'

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

export const initialize = (
  FIREBASE_KEY,
  AUTH_DOMAIN,
  PROJECT_ID,
  STORAGE_BUCKET,
  MSG_SENDER_ID,
  APP_ID,
  MEASUREMENT_ID
) => {
  const firebaseConfig = {
    apiKey: "AIzaSyB-kTP61wqywHQQJY2SGUb8dsRCIG98UXU",
    authDomain: "multivendor-api.firebaseapp.com",
    databaseURL: "https://multivendor-api-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "multivendor-api",
    storageBucket: "multivendor-api.appspot.com",
    messagingSenderId: "181797111157",
    appId: "1:181797111157:web:be928c5e58597f92b47f3c",
    measurementId: "G-5Q78MBWHVH"
  }

  // Initialize Firebase
  const app = firebase.initializeApp(firebaseConfig)
  const messaging = getMessaging(app)
  return messaging
}

export const isFirebaseSupported = async() => {
  return await isSupported()
}
